import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from '../login/LoginPage';
import MainPage from '../main/MainPage';

function HomePage() {
	return (
		<Routes>
			<Route path="/:id" element={<LoginPage />} />
			<Route exact path="/" element={<MainPage />} />
		</Routes>
	)

}

export default HomePage;