import * as React from 'react';
import { Button } from 'primereact/button';
import { UserContext } from '../../user-context';
import { useNavigate } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { Divider } from 'primereact/divider';
import { format, parseISO, formatISO } from 'date-fns';
import EditSetting from '../../components/settings/EditSetting';
import { useAxios } from '../../utils/hooks.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/pro-light-svg-icons';
import { Editor } from 'primereact/editor';
import AppointmentSummary from '../../components/tickets/AppointmentSummary.js';

const MenuEnd = ({ user }) => {
	
	const navigate = useNavigate();

	const logout = () => {
		const id = localStorage.getItem("companyId");
		navigate("/" + id);
	}

    return (
        <div>
            <Button style={{ marginLeft: 10 }} className="p-button-warning" label="Logout" icon='pi pi-sign-out' onClick={logout} />
        </div>
    )
}

function MainPage() {
	const navigate = useNavigate();
	const { user } = React.useContext(UserContext);
	const [ selectedSetting, setSelectedSetting ] = React.useState();
    const [ editSettingVisible, setEditSettingVisible ] = React.useState(false);
	const [ noteText, setNoteText ] = React.useState();
    const [ saving, setSaving ] = React.useState(false);
    const axiosInstance = useAxios();
    const toast = React.useRef(null);

    const sendNote = () => {
        if (!noteText || noteText === '') return;
        setSaving(true);
        const note = {
            text: noteText,
            ticketId: user.ticket.ticketId,
            companyId: user.ticket.companyId,
            customerId: user.ticket.customerId,
            createTime: formatISO(new Date())
        }
        axiosInstance.current.post("/portal/note", note).then((response) => {
            toast.current.show({severity: 'success', summary: 'Note Saved!', detail: 'Note saved to ticket succesfully!'})
            user.ticket.notes = [note, ...user.ticket.notes];
        })
        .catch((err) => {
            console.error(err);
            toast.current.show({severity: 'error', summary: 'Error!', detail: 'Note was not saved! Please try again or contact support.'});
        })
        .finally(() => {
			setNoteText("");
            setSaving(false);
        })
    }


	const toggleEditSettingVisible = () => {
        setEditSettingVisible(!editSettingVisible);
    }

	const valueBodyTemplate = (rowData) => {
        if (rowData.value !== '') {
            return (<div>rowData.value</div>)
        } else {
            return (<div>{rowData.enabled ? 'Enabled' : 'Disabled'}</div>)
        }
    }

    const editSettingBody = (rowData) => {
        return <Button className="p-button-text" label="View / Edit" icon="pi pi-pencil" onClick={() => {
            setSelectedSetting(rowData);
            toggleEditSettingVisible();
        }}/>
    }

	const priceBody = (rowData) => {
		return <div>{rowData.charge.price.toFixed(2)}</div>
	}

	const subtotalBody = (rowData) => {
		return <div className="flex-row end">{rowData.subtotal.toFixed(2)}</div>
	}

	React.useEffect(() => {
		if (user.customer.id === 'no') {
			navigate("/login");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Toast ref={toast} />
			<EditSetting setting={selectedSetting} visible={editSettingVisible} hide={toggleEditSettingVisible} />
			<div className="mt20">
				<div className="flex-row between">
					<div className="title">
						{user.customer.firstName + ' ' + user.customer.lastName}
					</div>
					<MenuEnd user={user}/>
				</div>
				
				<div className="flex-row wrap">
					<div className="flex-col half-width">
						<div className="secondary-title">Email:</div>
						<div className="tertiary-title">{user.customer.email}</div>
						<div className="secondary-title">Phone:</div>
						<div className="tertiary=title">{user.customer.phone}</div>
						{user.phoneAlt ?
							<>
								<div className="secondary-title">Alternate Phone:</div>
								<div className="tertiary-title">{user.customer.phoneAlt}</div>
							</>
						:
							<></>
						}
						{user.fax ?
							<>
								<div className="secondary-title">Fax:</div>
								<div className="tertiary-title">{user.customer.fax}</div>
							</>
						:
							<></>
						}
					</div>
					<div className="flex-col half-width">
						<div className="secondary-title">Address:</div>
						<div className="tertiary-title">{user.customer.addressOne}</div>
						<div className="tertiary-title">{user.customer.addressTwo}</div>
						<div className="tertiary-title">{user.customer.city}, {user.customer.state} {user.customer.zip}</div>
					</div>
				</div>
				<div className="mt30">
					<TabView className="tabview-header-icon">
						<TabPanel header="Ticket Details" leftIcon="pi pi-fw pi-file">
							<div className="title">
								Ticket: {user.ticket.ticketId.substr(0,8)}
							</div>
							{user.ticket.estimate && <div className="secondary-title pt20" style={{ color: 'var(--bs-green)', fontWeight: 'bold'}} ><FontAwesomeIcon icon={faExclamation} /> ESTIMATE <FontAwesomeIcon icon={faExclamation} /></div>}
							<div className="secondary-title">
								Status: {user.ticket.status}
							</div>
							<DataTable
								className="full-width"
								paginator
								rows={10}
								value={user.ticket.lineItems}
								responsiveLayout="stack"
								size="small"
								dataKey="lineItemId"
								emptyMessage="No line items created yet"
								// filters={lineItemFilters}
								filterDisplay='row'
							>
								<Column field="charge.sku" header="SKU" sortable filter filterPlaceholder="Search by SKU"></Column>
								<Column field="charge.upc" header="UPC" sortable filter filterPlaceholder="Search by UPC"></Column>
								<Column field="charge.description" header="Description" sortable filter filterPlaceholder="Search by description"></Column>
								<Column body={priceBody} header={`Price (${user.settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
								<Column field="quantity" header="Quantity" sortable ></Column>
								<Column field="discount.code" header="Discount" sortable ></Column>
								<Column body={subtotalBody} header={`Subtotal (${user.settings.find(s => s.key === 'Currency')?.value})`} sortable></Column>
								{/* <Column body={lineItemEditBody}></Column> */}
								{/* <Column body={lineItemRemoveBody}></Column> */}
							</DataTable>
							<div className="flex-row end secondary-title">Subtotal: {user.settings.find(s => s.key === 'Currency')?.value}{user.ticket.subtotal.toFixed(2)}</div>
							<div className="flex-row end secondary-title">Tax ({user.settings.find(s => s.key === 'Tax')?.value}%): {user.settings.find(s => s.key === 'Currency')?.value}{user.ticket.tax.toFixed(2)}</div>
							<div className="flex-row end secondary-title mt10">Total: {user.settings.find(s => s.key === 'Currency')?.value}{user.ticket.total.toFixed(2)}</div>
							<Divider />
							{user.invoice ?
								<>
								<div className="flex-row end secondary-title">Payments: {user.settings.find(s => s.key === 'Currency')?.value}{user.invoice?.paymentTotal.toFixed(2)}</div>
								<div className="flex-row end secondary-title">Due: {user.settings.find(s => s.key === 'Currency')?.value}{(user.ticket.total - user.invoice?.paymentTotal).toFixed(2)}</div>
								</>
							:
								<></>
							}
							<div>
								<div className="title">
									Appointments
								</div>
								{user.ticket.appointments.map(a => {
									return <AppointmentSummary appointment={a} />
								})}
							</div>
							<Divider />
							<div>
								<div className="title">
									Ticket Notes
								</div>
								<div className="flex-col">
									<InputTextarea rows={5} cols={5} maxLength={500} value={noteText} onChange={(e) => setNoteText(e.target.value)} placeholder="Attach note to ticket"/> 
                					<Button style={{ width: '30%', margin: '20px 0' }} className="p-button-primary" label="Send Note" icon='pi pi-send' loading={saving} onClick={sendNote} />
								</div>
								{user.ticket.notes.filter(n => !n.internal).map(note => (
									<div className="mt10" key={note.noteId}>
										<Card>
											<div className="flex-row between">
												<div>
													<Editor name="note" id="note" style={{ height: '100px', width: '500px'}} value={note.text} readOnly showHeader={false}/>
													<div className="audit-details mt10">Created by: {note.author ? note.author : 'Customer'} on {format(parseISO(note.createTime), "MMMM dd, yyyy h:mm a")}</div>
												</div>
											</div>
										</Card>
									</div>
								))}
							</div>
						</TabPanel>
						<TabPanel header="Settings" leftIcon="pi pi-fw pi-money-bill">
							<DataTable
								className="full-width"
								paginator
								rows={10}
								value={user.customer.settings}
								responsiveLayout="stack"
								size="small"
								selectionMode="single"
								dataKey="settingId"
							>
								<Column field="description" header="Setting" sortable></Column>
                                <Column body={valueBodyTemplate} header="Value" sortable></Column>
                                <Column body={editSettingBody}></Column>
							</DataTable>
						</TabPanel>
					</TabView>
				</div>
			</div>
		</>
	)

}

export default MainPage;