import React from "react"

export const user = {
	customer: null,
	ticket: null,
	invoice: null,
	settings: null
}

export const UserContext = React.createContext({
	user: user,
	setUser: () => {}
});