import * as React from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../user-context';
import { useAxios } from '../../utils/hooks.ts';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

function LoginPage() {
    const [ company, setCompany ] = React.useState();
    const [ loading, setLoading ] = React.useState(true);
    // eslint-disable-next-line
	const [ formData, setFormData ] = React.useState({});
    const [ saving, setSaving ] = React.useState(false);
	const { setUser } = React.useContext(UserContext);
	const { id } = useParams();
	const axiosInstance = useAxios();
    const navigate = useNavigate();
    const toast = React.useRef(null);

	const formik = useFormik({
        initialValues: {
            ticketId: '',
            lastName: '',

        },
        validate: (data) => {
            let errors = {};

            if (!data.ticketId) {
                errors.ticketId = 'ID is required.';
            }
            if (!data.lastName) {
                errors.lastName = 'Last name is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setSaving(true);
            setFormData(data);
            data.companyId = id;
            axiosInstance.current.post("/portal/login", data).then((response) => {
                formik.resetForm();
                const tax = response.data.settings.find(s => s.key === 'Tax').value;
                const currency = response.data.settings.find(s => s.key === 'Currency').value;
                if (response.data.invoice) {
                    let i = response.data.invoice;
                    i.displayName = i.invoiceId.substring(0, 8);
					i.createTimeDisplay = format(parseISO(i.createTime), "MMMM dd, yyyy h:mm a")
                    i.currency = currency;
                    let paymentTotal = 0;
                    i.payments.forEach(p => {
                        paymentTotal += p.amount;
                    })
                    i.paymentTotal = paymentTotal;
                }
                let t = response.data.ticket;
                t.currency = currency;
                t.displayName = t.ticketId.substring(0, 8);
                t.displayCreateTime = format(parseISO(t.createTime), "MMMM dd, yyyy h:mm a")
                t.assignedFullname = t.assigned ? t.assignedFullname : 'Unassigned';
                t.subtotal = 0;
                t.tax = 0;
                t.lineItems.forEach(i => {
                    let amount = 0.00;
                    if (i.discount) {
                        amount = i.charge.price * i.quantity * (i.discount.percentage ? 1 - (i.discount.percentage / 100) : 1) - (i.discount.amount ? i.discount.amount : 0);
                    } else {
                        amount = i.charge.price * i.quantity;
                    }
                    i.subtotal = amount;
                    if (!i.charge.exempt) {
						t.tax += Math.round((amount * (tax / 100) * 100) + Number.EPSILON) / 100 * 1.0;
					}
                    t.subtotal += i.subtotal;
                })
                t.total = t.subtotal + t.tax;
                setUser(response.data);
                navigate("/");
            })
            .catch((err) => {
                console.error(err);
                toast.current.show({severity: 'error', summary: 'Bad Login', detail: 'ID and Name not found. Please try again or contact support.'})
            })
            .finally(() => {
                setSaving(false);
            })
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

	React.useEffect(() => {
        setLoading(true);
		localStorage.setItem('companyId', id);
        axiosInstance.current.get("/portal/company/" + id).then((response) => {
            setCompany(response.data);
        })
        .finally(() => {
            setLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
        <>
            <Toast ref={toast} />
            {loading ?
                <div className="flex-row center align-center" style={{ height: '100vh'}}>
                <ProgressSpinner />
            </div>
            :
                <div style={{ marginTop: 100 }} className="flex-row justify-content-center">
                    <div style={{ alignItems: 'center' }} className="card">
                        <div className="title m30">
                            {company.name}
                        </div>
                        <Image src={company.logo} width='200' />
                        <form onSubmit={formik.handleSubmit} className="p-fluid">
                            <div className="field m30">
                                <span className="p-float-label">
                                    <InputText id="ticketId" name="ticketId" value={formik.values.ticketId} onChange={formik.handleChange} autoComplete='off' className={classNames({ 'p-invalid': isFormFieldValid('ticketId') })} />
                                    <label htmlFor="ticketId" className={classNames({ 'p-error': isFormFieldValid('ticketId') })}>Ticket ID *</label>
                                </span>
                                {getFormErrorMessage('ticketId')}
                            </div>
                            <div className="field m30">
                                <span className="p-float-label">
                                    <InputText id="lastName" name="lastName" value={formik.values.lastName} onChange={formik.handleChange} autoComplete='off' className={classNames({ 'p-invalid': isFormFieldValid('lastName') })} />
                                    <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') })}>Last Name *</label>
                                </span>
                                {getFormErrorMessage('lastName')}
                            </div>

                            <div className="flex-row justify-content-center mt20">
                                <Button style={{ width: '50%' }} type="submit" label="Login" icon='pi pi-sign-in' loading={saving} />
                            </div>
                        </form>
                    </div>
                </div>
            }
        </>
		
	)
}

export default LoginPage;