import { format, parseISO } from "date-fns";
import { Card } from "primereact/card";
import { Editor } from "primereact/editor";

export default function AppointmentSummary({ appointment }) {
    return (
        <>
        <Card title={appointment.title} subTitle={format(parseISO(appointment.scheduledTime), 'dd MMM, yyyy HH:mm aa')} style={{ marginRight: 20, marginTop: 20, width: 500 }}>
            <div className="mt10">
                <div className="mt10">Duration: {appointment.duration} minutes</div>
                <Editor readOnly={true} value={appointment.notes} showHeader={false} />
            </div>
        </Card>
        </>
    )
}