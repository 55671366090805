import { BrowserRouter } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import Container from "react-bootstrap/Container";
import * as React from 'react';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import { UserContext } from "./user-context";

const defaultUser = {
  customer: null,
  ticket: null,
  invoice: null,
  settings: null
}

function App() {
  const [ user, setUser ] = React.useState(defaultUser);
  const value = { user, setUser };

  return (
    <UserContext.Provider value={value}>
      <BrowserRouter>
        <Container>
          <HomePage />
        </Container>
      </BrowserRouter>
    </UserContext.Provider>
  )
}

export default App;
