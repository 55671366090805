import { useEffect, useRef } from 'react';
import axios from 'axios';
import type { AxiosInstance } from 'axios';

export const useAxios = () => {
  const axiosInstance = useRef<AxiosInstance>();
  const baseURL = process.env.REACT_APP_API_URL || '/api';

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL,
    });

    return () => {
      axiosInstance.current = undefined;
    };
  }, [baseURL]);

  return axiosInstance;
};